import type {ImageProps} from '@/components/base/elements/Image/Image';
import Image from '@/components/base/elements/Image/Image';

export interface AppButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  image: ImageProps;
  componentName?: string;
}

export default function AppButton({
  image,
  componentName,
  href,
  ...props
}: AppButtonProps) {
  return (
    <a
      className="shrink-0"
      href={href}
      data-component-name={componentName}
      {...props}
    >
      <Image {...image} className="h-[60px]" />
    </a>
  );
}
