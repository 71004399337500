import Image from '@/components/shared/Image/Image';
import type {AppButtonProps} from '@/pages/shopify.com/($locale)/mobile/components/DownloadApp/AppButton';

interface QrCodeProps extends AppButtonProps {
  label?: string;
  eventLabel?: string;
}

export default function QrCode({
  componentName,
  href,
  eventLabel,
  label,
  image,
  ...props
}: QrCodeProps) {
  return (
    <a
      className="overflow-hidden rounded-xl bg-white px-2.5 pt-[9px] pb-3.5 text-center underline hover:no-underline"
      data-event-label={eventLabel}
      data-component-name={componentName}
      href={href}
      {...props}
    >
      <Image {...image} className="h-[105px] mb-[5px]" />
      {label}
    </a>
  );
}
