import {json, type LoaderFunctionArgs} from '@remix-run/cloudflare';
import merge from 'lodash/merge';
import {useLoaderData} from '@remix-run/react';

import PageLayout from '@/components/shared/Page/PageLayout';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {ColorSchemeEnum} from '@/enums';
/* Utils */
import {pageLoader} from '@/utils/server/loader/pageLoader';
import type {LoaderResponse} from '@/utils/server/request.server';
/* Types */
import {localeImage} from '@/utils/image';
import Hero from '@/pages/shopify.com/($locale)/mobile/components/Hero/Hero';
import type {MobilePageContent} from '@/pages/shopify.com/($locale)/mobile/types';
import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import Card from '@/components/base/modules/Card/Card';
import SideBySideVideo from '@/components/pages/shared/SideBySideVideo/SideBySideVideo';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import SideBySide from '@/components/base/modules/SideBySide/SideBySide';
import Faq from '@/components/base/modules/Faq/Faq';
import Conversion from '@/pages/shopify.com/($locale)/mobile/components/Conversion/Conversion';

/* Links */
export {links} from '@/pages/shopify.com/($locale)/mobile/links';

/* Loader */
export const loader = async (args: LoaderFunctionArgs) => {
  const baseData = (await pageLoader(args)) as LoaderResponse;

  const {t} = args.context.i18n;

  const settingsJson = await args.context.getPageData('settings.json');
  const {images: settings} = JSON.parse(settingsJson ?? '{}');
  const {site} = baseData;

  const globalDownloadAppContent = {
    ios: {
      alt: t('global:downloadApp.iosAlt'),
      href: t('global:downloadApp.iosUrl'),
    },
    android: {
      alt: t('global:downloadApp.androidAlt'),
      href: t('global:downloadApp.androidUrl'),
    },
    qr: {
      alt: t('global:downloadApp.qrCodeAlt'),
      title: t('global:downloadApp.qrTitle'),
    },
  };

  const downloadAppProps = merge(
    t('pages/mobile/content:downloadApp'),
    globalDownloadAppContent,
  );

  const downloadAppLocalSettings = {
    ...settings.downloadApp,
    ios: {
      ...settings.downloadApp.ios,
      imageSrc: {
        ...settings.downloadApp.ios.imageSrc,
        mobile: localeImage<string>(
          settings.downloadApp.ios.imageSrc.mobile,
          site,
        ),
      },
    },
    android: {
      ...settings.downloadApp.android,
      imageSrc: {
        ...settings.downloadApp.android.imageSrc,
        mobile: localeImage<string>(
          settings.downloadApp.android.imageSrc.mobile,
          site,
        ),
      },
    },
  };

  const accordionItems = t(
    'pages/mobile/content:faq.accordionItems',
  ) as unknown as {heading: string; content: string}[];

  const accordionItemsIds = accordionItems.map((_content, index) => ({
    id: `faq-${index}`,
  }));

  const getResponsiveImage = (image: any) => ({
    ...image,
    src: localeImage<string>(image.src, site),
    srcSet: `${localeImage<string>(image.src, site)} 2x`,
  });

  const unmistakablyYoursVideoLocations = [
    'en',
    'en-GB',
    'de',
    'fr',
    'fr-CA',
    'ja',
  ];

  const unmistakablyYoursVideoSettings = settings.unmistakablyYours.video.video;

  const content: MobilePageContent = merge(
    {
      hero: merge(t('pages/mobile/content:hero'), {
        downloadAppProps,
      }),
      features: t('pages/mobile/content:features'),
      unmistakablyYours: t('pages/mobile/content:unmistakablyYours'),
      anytimeAnywhere: t('pages/mobile/content:anytimeAnywhere'),
      onTheGo: t('pages/mobile/content:onTheGo'),
      faq: t('pages/mobile/content:faq'),
      conversion: merge(t('pages/mobile/content:conversion'), {
        downloadAppProps,
      }),
    },
    {
      hero: {
        ...settings.hero,
        image: getResponsiveImage(settings.hero.image),
        downloadAppProps: downloadAppLocalSettings,
      },
      features: settings.features,
      unmistakablyYours: {
        ...settings.unmistakablyYours,
        video: {
          ...settings.unmistakablyYours.video,
          video: unmistakablyYoursVideoLocations.includes(site.locale) && {
            ...unmistakablyYoursVideoSettings,
            videoId: localeImage<string>(
              unmistakablyYoursVideoSettings.videoId,
              site,
            ),
            image: {
              ...unmistakablyYoursVideoSettings.image,
              srcSet: localeImage<string>(
                unmistakablyYoursVideoSettings.image.src,
                site,
              ),
            },
          },
          image: getResponsiveImage(unmistakablyYoursVideoSettings.image),
        },
      },
      anytimeAnywhere: {
        ...settings.anytimeAnywhere,
        image: getResponsiveImage(settings.anytimeAnywhere.image),
      },
      onTheGo: {
        ...settings.onTheGo,
        image: getResponsiveImage(settings.onTheGo.image),
      },
      faq: {
        ...settings.faq,
        accordionItems: {
          ...accordionItemsIds,
        },
      },
      conversion: {
        ...settings.conversion,
        downloadAppProps: downloadAppLocalSettings,
      },
    },
  );

  return json({...baseData, content, shareImage: settings.shareImage});
};

/* Handle */
export const handle = {
  metadata: {
    pageCategory: 'Manage',
    pageGroup: 'product',
    pagePath: '/mobile',
  },
};

export default function Mobile() {
  const {content} = useLoaderData<typeof loader>();
  const {
    hero,
    features,
    unmistakablyYours,
    anytimeAnywhere,
    onTheGo,
    faq,
    conversion,
  } = content as MobilePageContent;

  return (
    <PageLayout footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}>
      <Hero {...hero} />
      <Section
        topSpacing="2xl"
        bottomSpacing="2xl"
        mode="dark"
        sectionName="features"
        id="features"
      >
        <SectionHeader mode="dark" {...features.sectionHeader} />
        <CardGrid numberOfColumns={3} withContainer>
          {features.cards.map((card: any) => (
            <Card
              key={card.headingGroup.headingHtml}
              size="small"
              type="outline"
              mode="dark"
              {...card}
            />
          ))}
        </CardGrid>
      </Section>
      <Section
        topSpacing="2xl"
        bottomSpacing="2xl"
        className="side-by-sides-gradient"
        sectionName="create-store"
        id="create-store"
      >
        <SideBySideVideo
          {...unmistakablyYours.video}
          visualPosition="right"
          video={{
            ...unmistakablyYours.video.video,
            classNames: {
              wrapper: 'md:ml-auto rounded-[10px] overflow-hidden',
            },
          }}
        >
          <HeadingGroup size="t4" {...unmistakablyYours.headingGroup} />
        </SideBySideVideo>
        <SideBySide verticalAlign="center" image={anytimeAnywhere.image}>
          <HeadingGroup size="t4" {...anytimeAnywhere.headingGroup} />
        </SideBySide>
        <SideBySide
          verticalAlign="center"
          visualPosition="right"
          image={onTheGo.image}
        >
          <HeadingGroup size="t4" {...onTheGo.headingGroup} />
        </SideBySide>
      </Section>
      <Section topSpacing="2xl" sectionName="faq" id="faq">
        <SectionHeader {...faq.sectionHeader} />
        <Faq accordionItems={faq.accordionItems} />
      </Section>
      <Section
        topSpacing="2xl"
        bottomSpacing="2xl"
        className="conversion-gradient"
        sectionName="conversion"
        id="conversion"
      >
        <Conversion {...conversion} />
      </Section>
    </PageLayout>
  );
}
