import type {ImageProps} from '@/components/base/elements/Image/Image';
import Image from '@/components/base/elements/Image/Image';
import Section from '@/components/base/layouts/Section/Section';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import type {HeroProps} from '@/components/base/sections/Hero/types';
import {twMerge} from '@/stylesheets/twMerge';
import {componentName} from '@/components/base/sections/Hero/types';
import type {DownloadAppProps} from '@/pages/shopify.com/($locale)/mobile/components/DownloadApp/DownloadApp';
import DownloadApp from '@/pages/shopify.com/($locale)/mobile/components/DownloadApp/DownloadApp';

export interface MobileHeroProps extends HeroProps {
  downloadAppProps: DownloadAppProps;
  image: ImageProps;
}

export default function MobileHero({
  className,
  ctas,
  headingGroupProps,
  image,
  mode = 'light',
  downloadAppProps,
  ...props
}: MobileHeroProps) {
  return (
    <Section
      className={twMerge(
        'relative pt-global-header bg-[#D4F9E0] hero-gradient pb-0',
        className,
      )}
      errorBoundaryFullWidth={true}
      navbarPadding={true}
      sectionName={componentName}
      id="hero"
      {...props}
    >
      <TwoColumn className="gap-y-2xl md:gap-y-xl">
        <TwoColumn.Col1 className="flex flex-col pt-3xl sm:col-span-6 my-auto md:py-3xl gap-y-2xl md:gap-y-3xl">
          <HeadingGroup
            headingAs="h1"
            mode={mode}
            size="t1"
            {...headingGroupProps}
          />

          <DownloadApp {...downloadAppProps} />
        </TwoColumn.Col1>
        <TwoColumn.Col2
          withGutter={false}
          className="my-auto sm:col-start-1 sm:col-span-8 md:py-3xl"
        >
          {image && <Image className="mx-auto" {...image} />}
        </TwoColumn.Col2>
      </TwoColumn>
    </Section>
  );
}
