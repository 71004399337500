import {type Mode} from '@/components/base/types';
import HeadingGroup, {
  type HeadingGroupContentProps,
} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import DownloadApp from '@/pages/shopify.com/($locale)/mobile/components/DownloadApp/DownloadApp';
import type {DownloadAppProps} from '@/pages/shopify.com/($locale)/mobile/components/DownloadApp/DownloadApp';

export interface MobileConversionProps extends HeadingGroupContentProps {
  className?: string;
  headingClassName?: string;
  mode?: Mode;
  downloadAppProps: DownloadAppProps;
  componentName: string;
}

export default function MobileConversion({
  className,
  headingClassName,
  mode = 'light',
  kicker,
  headingHtml,
  subheadHtml,
  downloadAppProps,
  componentName,
}: MobileConversionProps) {
  const colProps = {
    start: {xs: 1, sm: 1, md: 2, lg: 3, xl: 3},
    span: {xs: 4, sm: 8, md: 10, lg: 8, xl: 8},
  };
  const headingGroupProps = {
    className: headingClassName,
    kicker,
    headingHtml,
    subheadHtml,
    center: true,
    mode,
  };
  return (
    <Grid componentName={componentName} className={className}>
      <Col {...colProps} className="text-center grid gap-y-md md:gap-y-sm">
        <HeadingGroup {...headingGroupProps} />
        <DownloadApp center {...downloadAppProps} />
      </Col>
    </Grid>
  );
}
