import cn from 'classnames';
import {useState, useEffect} from 'react';

import {checkMobile} from '@/utils/utils';
import QrCode from '@/pages/shopify.com/($locale)/mobile/components/DownloadApp/QrCode';
import AppButton from '@/pages/shopify.com/($locale)/mobile/components/DownloadApp/AppButton';

interface PlatformProps {
  componentName: string;
  label: string;
  eventLabel: string;
  imageSrc: {
    qr: string;
    mobile: string;
  };
  alt: string;
  href: string;
}

export interface DownloadAppProps {
  center?: boolean;
  ios: PlatformProps;
  android: PlatformProps;
  qr: {
    alt: string;
    title: string;
  };
}

export default function DownloadApp({
  center,
  ios,
  android,
  qr,
}: DownloadAppProps) {
  const [isMobileDevice, setIsMobileDevice] = useState<boolean | null>(null);
  useEffect(
    () => setIsMobileDevice(checkMobile(navigator.userAgent) === 'mobile'),
    [],
  );

  return (
    <div
      className={cn(
        'transition-[transform,opacity] transform ease-out duration-300 motion-reduce:transition-none',
        isMobileDevice !== null
          ? 'opacity-1 translate-y-0'
          : 'opacity-0 translate-y-1 h-14 lg:h-[10.7rem]',
      )}
    >
      {!isMobileDevice && (
        <>
          <p
            className={
              center ? 'text-body-lg mb-md' : 'mb-3 text-body-sm font-bold'
            }
          >
            {qr.title}
          </p>
          <div
            className={cn('flex items-center gap-16', {
              'justify-center': center,
            })}
          >
            <QrCode
              componentName={`${ios.componentName}-qr`}
              href={ios.href}
              eventLabel={ios.eventLabel}
              label={ios.label}
              image={{
                src: ios.imageSrc.qr,
                alt: ios.alt,
              }}
            />
            <QrCode
              componentName={`${android.componentName}-qr`}
              href={android.href}
              eventLabel={android.eventLabel}
              label={android.label}
              image={{
                src: android.imageSrc.qr,
                alt: android.alt,
              }}
            />
          </div>
        </>
      )}
      {isMobileDevice && (
        <div
          className={cn(
            'flex flex-wrap items-center justify-start gap-4 md:flex-nowrap',
            {
              'justify-center': center,
            },
          )}
        >
          {ios.href && (
            <AppButton
              image={{
                src: ios.imageSrc.mobile,
                alt: ios.alt,
              }}
              componentName={`${ios.componentName}-mobile`}
              href={ios.href}
            />
          )}
          {android.href && (
            <AppButton
              image={{
                src: android.imageSrc.mobile,
                alt: android.alt,
              }}
              componentName={`${android.componentName}-mobile`}
              href={android.href}
            />
          )}
        </div>
      )}
    </div>
  );
}
